var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-actions',{staticClass:"py-0"}),_c('v-divider',{staticClass:"py-0 my-0"}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogEdit),callback:function ($$v) {_vm.dialogEdit=$$v},expression:"dialogEdit"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Limits")]),_c('v-spacer'),_c('v-layout',[_c('v-flex',{staticClass:"pa-2",attrs:{"xs12":""}},[_c('label'),_c('v-icon',{staticClass:"right",staticStyle:{"float":"right"},on:{"click":_vm.closeEdit}},[_vm._v("cancel")])],1)],1)],1),_c('hr'),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Phone Number","append-icon":"phone","rules":_vm.phoneRules,"required":""},model:{value:(_vm.data.contactNumber),callback:function ($$v) {_vm.$set(_vm.data, "contactNumber", $$v)},expression:"data.contactNumber"}})],1)],1),_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"Email","append-icon":"email","rules":_vm.emailRules,"required":""},model:{value:(_vm.data.email),callback:function ($$v) {_vm.$set(_vm.data, "email", $$v)},expression:"data.email"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{attrs:{"color":"blue darken-1","text":"","block":"","elevation":"2","disabled":!_vm.valid},on:{"click":function($event){return _vm.updateEditData()}}},[_vm._v(" Update ")])],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getProductList,"search":_vm.search,"hide-default-footer":"","disable-pagination":"","hide-default-header":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.contactNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.contactNumber)+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:"item.viewAction",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","fab":"","color":"info"},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)})],1),_c('v-row',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }